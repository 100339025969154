// @mui
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
// hooks
// _mock
// components
import { useSettingsContext } from 'src/components/settings';
// assets
import { MotivationIllustration } from 'src/assets/illustrations';
//
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'src/components/snackbar';
import useHasRoles from 'src/hooks/use-has-roles';
import { useLocales } from 'src/locales';
import { RootState, useSelector } from 'src/redux/store';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { fDateTime } from 'src/utils/format-time';
import MetricsFilters from '../metrics-filters';
import MetricsPieChart from '../metrics-pie-chart';
import TotalizerWidget from '../metrics-totalizer';

// ----------------------------------------------------------------------

const defaultFilters: {
  startDate: string;
  endDate: string;
  billingPlan?: string;
  stores?: string[];
} = {
  startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'), // 7 días atrás incluyendo hoy
  endDate: moment().format('YYYY-MM-DD'), // hoy
  billingPlan: '',
  stores: [],
};


export default function MetricsView() {
  const { t } = useLocales();
  const [metrics, setMetrics] = useState<any>();  
  const stateStore: any = useSelector((state: RootState) => state.store);
  // tiendas elegidas para las metricas
  const [filters, setFilters] = useState(defaultFilters);
  const { enqueueSnackbar } = useSnackbar();



  const settings = useSettingsContext();

  const canReset = !isEqual(defaultFilters, filters);
  const handleFilters = useCallback((name: string, value: string) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  useEffect(() => {
    if (filters.startDate != "" && filters.endDate != "" && (filters.stores && filters.stores?.length > 0)) {
      getConversations();
    }
  }, [filters]);
 
  const getConversations = useCallback(() => {
    const URL = API_ENDPOINTS.dashboard.count;
    const { startDate, endDate, stores } = filters;
    if (startDate && endDate) {
      if (new Date(startDate) > new Date(endDate)) {
        enqueueSnackbar('La fecha fin debe ser mayor que la fecha inicio', { variant: 'warning' });
        return;
      }
    }
    let PARAMS: any = {
      since: filters.startDate ? filters.startDate : '',
      to: filters.endDate ? filters.endDate : '',
      stores: stores
    };
    axios
      .post(URL, PARAMS)
      .then(({ data }) => {
        const { conversations, customers, messages, average_conversation_duration, merchant_involved, merchant_messages } = data;        
        let average_conversation_duration_formatted = '';
        if (average_conversation_duration) {
          const totalSeconds = average_conversation_duration * 60; // Convertir minutos a segundos
          const hours = Math.floor(totalSeconds / 3600);
          const minutes = Math.floor((totalSeconds % 3600) / 60);
          const secs = Math.floor(totalSeconds % 60);
          average_conversation_duration_formatted = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
       }
        setMetrics((prevMetrics: any) => ({
          ...prevMetrics,
          conversations: conversations,
          customers: customers,
          messages: messages,
          average_conversation_duration: average_conversation_duration_formatted,
          merchant_involved: merchant_involved,
          merchant_messages: merchant_messages,
        }));
      })
      .catch((error) => { });
  }, [enqueueSnackbar, filters]);
  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Grid container spacing={3}>
        <Grid xs={12} md={12}>
          <MetricsFilters
            title="¡Revisá las métricas de los merchants!"
            description="Elige un rango de fechas que quieras. ¡Por defecto verás los datos de la última semana!"
            filters={filters}
            onFilters={handleFilters}
            onResetFilters={() => {
              setFilters(defaultFilters);
            }}
            canReset={canReset}
            img={<MotivationIllustration />}
            action={
              <></>
            }
          />
        </Grid>
        <Grid xs={6} md={4}>
          <TotalizerWidget
            title="Cantidad de conversaciones"
            percent={0}
            total={metrics?.conversations || 0}
            chart={{
              options: { title: { text: 'Última semana', align: 'center' } },
            }}
          />
        </Grid>
        <Grid xs={6} md={4}>
          <TotalizerWidget
            title="Duración promedio de las conversaciones"
            percent={0}
            total={metrics?.average_conversation_duration || 0}
            chart={{
              options: { title: { text: 'Última semana', align: 'center' } },
            }}
          />
        </Grid>
        <Grid xs={6} md={4}>
          <TotalizerWidget
            title="Cantidad de clientes únicos"
            percent={0}
            total={metrics?.customers || 0}
            chart={{
              options: { title: { text: 'Última semana', align: 'center' } },
            }}
          />
        </Grid>
        <Grid xs={6} md={4}>
          <TotalizerWidget
            title="Mensajes enviados por el asistente"
            percent={0}
            total={metrics?.messages?.bot || 0}
            chart={{
              options: { title: { text: 'Última semana', align: 'center' } },
            }}
          />
        </Grid>
        <Grid xs={6} md={4}>
          <TotalizerWidget
            title="Mensajes enviados por el merchant"
            percent={0}
            total={metrics?.messages?.merchant || 0}
            chart={{
              options: { title: { text: 'Última semana', align: 'center' } },
            }}
          />
        </Grid>
        <Grid xs={6} md={4}>
          <TotalizerWidget
            title="Mensajes enviados por el cliente"
            percent={0}
            total={metrics?.messages?.customer || 0}
            chart={{
              options: { title: { text: 'Última semana', align: 'center' } },
            }}
          />
        </Grid>


        <Grid xs={12} md={4}>
            <MetricsPieChart
              title="Mensajes de IA"
              chart={{
                series: (metrics?.messages) ? [
                  { label: 'Mensajes de IA', value: metrics?.messages?.bot },
                  { label: 'Mensajes Merchant', value: (metrics?.messages?.merchant) },
                ] : [],
                colors: ['#622E9A', '#1BA09A']
              }}
            />               
        </Grid>        
        <Grid xs={12} md={4}>
            <MetricsPieChart
              title="Conversaciones intervenidas por el merchant"
              chart={{
                series: (metrics?.merchant_involved) ? [
                  { label: 'Conversaciones intervenidas', value: metrics?.merchant_involved },
                  { label: 'Conversaciones no intervenidas', value: (metrics?.conversations - metrics?.merchant_involved) },
                ] : [],
                colors: ['#F24B25', '#2ED3BB']
              }}
            />               
        </Grid>        
        <Grid xs={12} md={4}>
            <MetricsPieChart
              title="Fuente de intervención del merchant"
              chart={{
                series: (metrics?.merchant_messages) ? [
                  { label: 'Whatsapp', value: parseInt(metrics?.merchant_messages?.application, 10) },
                  { label: 'Panel', value: parseInt(metrics?.merchant_messages?.panel, 10) },
                ] : [],
                colors: ['#33BDAF', '#357CD2']
              }}
            />               
        </Grid>        
      </Grid>
    </Container>
  );
}
