import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ChatHeaderDetail from 'src/sections/chat/chat-header-detail';
import ChatMessageInput from 'src/sections/chat/chat-message-input';
import axios, { API_ENDPOINTS } from 'src/utils/axios';

import { useChat } from 'src/sections/chat/hooks';
// _mock

// @mui
import { Box, Button, Card, Drawer, IconButton, TextField } from '@mui/material';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
// routes
// components
import { RootState, useDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useSettingsContext } from 'src/components/settings';

import { useParams } from 'src/routes/hook';

import { IConversation, IConversationMessage } from 'src/types/conversation';
import { useSnackbar } from 'src/components/snackbar';
import { useBoolean } from 'src/hooks/use-boolean';
import { Mixpanel } from 'src/Mixpanel';
import { TypeAnimation } from 'react-type-animation';

import { TableNoData } from 'src/components/table';
import Typography from '@mui/material/Typography';
import ChatRoom from 'src/sections/chat/chat-room';
import useHasRoles from 'src/hooks/use-has-roles';
import StoreSeach from 'src/components/store-search/store-search';
import EmptyContent from 'src/components/empty-content';
import Iconify from 'src/components/iconify';
import isEqual from 'lodash/isEqual';
import { fDateTime } from 'src/utils/format-time';
import Image from 'src/components/image/image';
import { useLocales } from 'src/locales';
import { setNotificationConversationUpdate } from 'src/redux/slices/notification';
import ConversationHeaderCompose from './conversation-header-compose';
import ConversationNav from './conversation-nav';
import ConversationMessageList from './conversation-message-list';
import ConversationMessageInput from './conversation-message-input';
import ConversationFilter from './conversation-filter';
import ConversationRoom from './conversation-room';

//

// ----------------------------------------------------------------------

type Props = {
  conversationId: any;
  onUpdateChat: any;
  onParentEvent: any;
};

export default function ConversationChatView({ conversationId, onUpdateChat,onParentEvent }: Props) {
  const settings = useSettingsContext();

  const params = useParams();
  const loadingButton = useBoolean();
  const { id = '' } = params;
  const { enqueueSnackbar } = useSnackbar();
  const isAdmin = useHasRoles(['ROLE_ADMIN']);
  const { t } = useLocales();
  const [currentConversation, setCurrentConversation] = useState<any>();
  const [pausedUser, setPausedUser] = useState<any>();
  const [showOrder, setShowOrder] = useState<boolean>(false);
  const [orderTrigger, setOrderTrigger] = useState<number>(0);
  const newMessageFlag = useBoolean(false);
  /** mock para el chat */
  const {
    contacts,
    recipients,
    conversationsStatus,
    currentConversationId,
    participantsInConversation,
    //
    onAddRecipients,
  } = useChat();

  const details = !!currentConversationId;
  const [selectedStore, setSelectedStore] = useState('');
  const [conversations, setConversations] = useState<IConversation[]>([]);
  const [loadingConversations, setLoadingConversations] = useState<boolean>(true);
  const [bannedUsers, setBannedUsers] = useState<any[]>([]);
  const [loadBannedUsers, setLoadBannedUsers] = useState<boolean>(false);
  const [loadingMessages, setLoadingMessages] = useState<boolean>(false);
  const notification = useSelector((state: any) => state.notification);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [bannedUser, setBannedUser] = useState<any>();
  const dispatch = useDispatch();
  const stateStore: any = useSelector((state: RootState) => state.store);
  const [page, setPage] = useState<number>(0);
  const [totalConversations, setTotalConversations] = useState<number>(0);

  const [imgUrl, setImgUrl] = useState<string | null>(null);

  const [documentUrl, setDocumentUrl] = useState<string | null>(null);
  const currentConversationRef = useRef(currentConversation);
  const markAsSeen = useCallback((messageId: string) => {
    axios
      .put(API_ENDPOINTS.message.markAsSeen(messageId))
      .then(({ data }) => {})
      .catch((error) => {
        // Manejo de errores
      });
  }, []);

  const getConversationDetail = useCallback(() => {
    if (!conversationId) return;
    // setLoadingMessages(true);
    axios
      .get(API_ENDPOINTS.conversation.details(conversationId || ''))
      .then(({ data }) => {
        const conversation = data;
        if (data.unreadMessages > 0) {
          data.messages.forEach((message: IConversationMessage) => {
            if (!message.saw) {
              markAsSeen(message.id);
            }
            conversation.unreadMessages -= conversation.unreadMessages;
          });
        }
        setCurrentConversation(conversation);

        setLoadingMessages(false);

      })
      .catch((error) => {
        // Manejo de errores
      });
  }, [markAsSeen, conversationId]);
  const handleNewMessageRef = useRef<() => void>();

  handleNewMessageRef.current = () => {
    newMessageFlag.onTrue();
    setTimeout(() => {
      newMessageFlag.onFalse();
    }, 100); // Ajusta el tiempo según sea necesario
  };

  /* useEffect(() => {
   
    handleNewMessageRef.current?.();
  }, [currentConversation]); */

  useEffect(() => {
    if (conversationId) {
      setLoadingMessages(true);
      getConversationDetail();
    }
  }, [conversationId, getConversationDetail]);

  const getConversations = useCallback(() => {
    if (selectedStore) {
      const QUERY = `?page=${page}&length=100`;
      const URL = isAdmin
        ? API_ENDPOINTS.conversation.listWhatsappByStore(selectedStore, QUERY)
        : API_ENDPOINTS.conversation.listWhatsappGroupedByStore(selectedStore, QUERY);
      axios
        .get(URL)
        .then(({ data }) => {
          setConversations(data.rows);

          setLoadingConversations(false);
          setTotalConversations(data.total);
        })
        .catch((error) => {
          // Manejo de errores
        });
    }
  }, [selectedStore, isAdmin, page]);

  const handlePaginationChange = () => {
    let page_aux = page;
    setPage((page_aux += 1));
  };

  const onShowImagePreview = (tempUrl: any) => {
    setImgUrl(tempUrl);
  };

  const onShowDocumentPreview = (tempUrl: any) => {
    setDocumentUrl(tempUrl);
  };

  useEffect(() => {
    if (notification.conversationUpdate) {
      const conversationUpdate = notification.conversationUpdate;
      if (conversationUpdate && conversationUpdate.conversation_id) {
        if (conversationUpdate.conversation_id === currentConversation?.id) {
        
          getConversationDetail();
        }
      }
      dispatch(setNotificationConversationUpdate(false));
      // getConversations();
    }
  }, [notification, dispatch, getConversations, currentConversation, getConversationDetail]);

  const sendAudio = async (message: any) => {
    const buffer = await message.arrayBuffer();
    const binaryString = Array.from(new Uint8Array(buffer), (byte) =>
      String.fromCharCode(byte)
    ).join('');
    const theAudio = btoa(binaryString);
    const DATA = {
      message: theAudio,
    };
    axios
      .post(API_ENDPOINTS.conversation.sendAudio(currentConversation.id), DATA)
      .then(({ data }) => {
        const newMessage = {
          ...data,
          role: 'assistant',
        };
        /*
        setCurrentConversation((prevConversation: IConversation) => ({
          ...prevConversation,
          messages: [...prevConversation.messages, newMessage]
        }));
        */
        Mixpanel.track('New Message', { source: 'store-panel' });
      })
      .catch((error) => {
        enqueueSnackbar(t(`${error.error}`), { variant: 'error' });
      });
  };

  const sendImage = async () => {
    if (!imgUrl) {
      enqueueSnackbar('Ha ocurrido un error al cargar la imagen', { variant: 'error' });
      return;
    }

    const response = await fetch(imgUrl);
    const blob = await response.blob();

    const mimeType = response.headers.get('Content-Type');
    // Leer el Blob como ArrayBuffer
    const buffer = await blob.arrayBuffer();
    const binaryString = Array.from(new Uint8Array(buffer), (byte) =>
      String.fromCharCode(byte)
    ).join('');

    // Convertir el string binario a base64
    const base64Data = btoa(binaryString);

    const theImage = `data:${mimeType};base64,${base64Data}`;

    // Datos para enviar
    const DATA = {
      message: theImage,
    };
    setImgUrl(null);
    axios
      .post(API_ENDPOINTS.conversation.sendImage(currentConversation.id), DATA)
      .then(({ data }) => {
        const newMessage = {
          ...data,
          role: 'assistant',
        };
        getConversationDetail();
        Mixpanel.track('New Message', { source: 'store-panel' });
      })
      .catch((error) => {
        enqueueSnackbar(t(`${error.error}`), { variant: 'error' });
      });
  };

  const sendMessage = (message: any) => {
    const newMessage = {
      class: 'message-store',
      content: message,
      role: 'assistant',
      created_at: new Date().toISOString(),
    };
    setCurrentConversation((prevConversation: IConversation) => ({
      ...prevConversation,
      messages: [...prevConversation.messages, newMessage],
    }));
    handleNewMessageRef.current?.();
    axios
      .post(API_ENDPOINTS.conversation.sendMessage(currentConversation.id), { message })
      .then(({ data }) => {
      
        /* const newMessage = {
          ...data,
          role: 'assistant',
        };
*/
        // getConversationDetail();

        Mixpanel.track('New Message', { source: 'store-panel' });
      })
      .catch((error) => {
        enqueueSnackbar(t(`${error.error}`), { variant: 'error' });
      });
  };
  const checkBanStatus = (channel: string, username: string) => {
    axios
      .get(API_ENDPOINTS.bannedUsers.check(channel, username))
      .then(({ data }) => {
        setBannedUser(data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    if (currentConversation) {
      const { username, channel } = currentConversation;
      checkBanStatus(channel.id, username);
    }
  }, [currentConversation]);

  // obtengo los canales de comunicación de esa tienda para luego buscar los usuarios baneados
  const getStoreChannels = useCallback(() => {
    axios
      .get(API_ENDPOINTS.channel.store(selectedStore))
      .then(({ data }: any) => {
        if (data.length === 0) {
          setLoadBannedUsers(true);
        }
        data.forEach((channel: any, index: number) => {
          // por cada canal busco los usuarios banneados y los agrego a la colección
          axios
            .get(API_ENDPOINTS.bannedUsers.whatsapp(channel.id))
            .then(({ data: userData }: any) => {
              setBannedUsers((prevData) => [...prevData, ...userData]);
              if (index === data.length - 1) {
                setLoadBannedUsers(true);
              }
            })
            .catch((err) => setLoadBannedUsers(true));
        });
      })
      .catch((error) => {
        // setLoadBannedUsers(true);
        // Manejo de errores
      });
  }, [selectedStore]);

  useEffect(() => {
    // setCurrentConversation(undefined);
    if (selectedStore) {
      setLoadBannedUsers(false);
      getStoreChannels();
    }
  }, [selectedStore, getStoreChannels]);

  const fetchConversations = useCallback(() => {
    getConversations();
  }, [getConversations]);

  useEffect(() => {
    setSelectedStore(stateStore.id);
  }, [stateStore.id]);

  useEffect(() => {
    if (selectedStore && loadBannedUsers) {
      setLoadingConversations(true);
      fetchConversations();
    }
  }, [selectedStore, page, fetchConversations, loadBannedUsers]);

  const fetchMoreConversations = () => {
    axios
      .get(
        API_ENDPOINTS.conversation.getConversationByUsernameAndChannel(
          currentConversation.username,
          currentConversation.channel.id,
          currentPage
        )
      )
      .then(({ data }) => {
        const newMessages = data.rows[0].messages;

        setCurrentConversation((prevConversation: IConversation) => {
          const updatedConversation = {
            ...prevConversation,
            messages: [...newMessages, ...prevConversation.messages],
          };
          return updatedConversation;
        });

        setCurrentPage(currentPage + 1);
      })
      .catch((error) => {
        // Manejo de errores
      });
  };

  const onChangeQualification = () => {
    getConversationDetail();
  };

  const onChangeBannedState = () => {
    loadingButton.onTrue();
    if (bannedUser) {
      // if the user was banned, revert it
      axios
        .delete(API_ENDPOINTS.bannedUsers.unban(bannedUser.id))
        .then(({ data }) => {
          enqueueSnackbar('Bot activado');
          setBannedUser(null);
          loadingButton.onFalse();
        })
        .catch((error) => {
          enqueueSnackbar('Hubo un error al habilitar el usuario', { variant: 'error' });
          loadingButton.onFalse();
          // Manejo de errores
        });
    } else {
      const { name, username, channel, store } = currentConversation;
      axios
        .post(API_ENDPOINTS.bannedUsers.ban, {
          name,
          username,
          channel: channel.id,
          store: store.id,
        })
        .then(({ data }) => {
          enqueueSnackbar('Bot pausado');
          checkBanStatus(channel.id, username);
          loadingButton.onFalse();
        })
        .catch((error) => {
          enqueueSnackbar('Hubo un error al bloquear el usuario', { variant: 'error' });
          loadingButton.onFalse();
          // Manejo de errores
        });
    }
  };

  const checkPausedUser = (channel: string, username: string) => {
    axios
      .get(API_ENDPOINTS.pausedUsers.check(channel, username))
      .then(({ data }) => {
        setPausedUser(data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    if (currentConversation) {
      const { username, channel } = currentConversation;
      checkPausedUser(channel.id, username);
    }
  }, [currentConversation]);

  const onChangePausedUser = () => {
   
    if (pausedUser) {
      axios
        .delete(API_ENDPOINTS.pausedUsers.activate(pausedUser.id))
        .then(({ data }) => {
          enqueueSnackbar('Bot activado');
          setPausedUser(null);
        })
        .catch((error) => {
          enqueueSnackbar('Hubo un error al pausar el bot', { variant: 'error' });
        });
    } else {
      const { name, username, channel, store } = currentConversation;
      axios
        .post(API_ENDPOINTS.pausedUsers.pause, {
          name,
          username,
          channel: channel.id,
          store: store.id,
        })
        .then(({ data }) => {
          enqueueSnackbar('Bot pausado');
          checkPausedUser(channel.id, username);
        })
        .catch((error) => {
          enqueueSnackbar('Hubo un error al pausar el usuario', { variant: 'error' });
        });
    }
  };

  const renderHead = (
    <Stack sx={{ pr: 1, pl: 2.5, py: 1, minHeight: 52 }}>
      {details ? (
        <ChatHeaderDetail participants={participantsInConversation} />
      ) : (
        <ConversationHeaderCompose
          currentConversation={currentConversation}
          loadingState={loadingButton.value}
          onChangeQualification={onChangeQualification}
          onChangePausedUser={onChangePausedUser}
          pausedUser={pausedUser}
          onViewOrder={() => {
            setShowOrder(true);
            setOrderTrigger((prev) => prev + 1);
          }}
          onParentEvent={onParentEvent}
        />
      )}
    </Stack>
  );

  const handleCloseImage = () => {
    setImgUrl(null);
  };

  const renderMessages = (
    <Stack
      sx={{
        width: '100%',
        height: {xs:'100vh', sm: '100%'},  
        overflow: 'hidden',
        position: 'relative',
        backgroundImage: 'url(/assets/background/conversations-background.jpg)',
      }}
    >


              {imgUrl && (
                <Box
                  sx={{
                    width: '100%',
                    height: '2000vh',
                    position: 'relative',
                    backgroundColor: '#E9EDEF',
                  }}
                >
                  <Box
                    component="img"
                    src={imgUrl}
                    alt="Preview"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                  <Box sx={{ position: 'absolute', top: 2, left: 20 }}>
                    <IconButton
                      onClick={handleCloseImage}
                      sx={{
                        backgroundColor: 'grey.100',
                        '&:hover': {
                          backgroundColor: 'grey.400',
                        },
                      }}
                    >
                      <Iconify icon="eva:close-fill" color="black" />
                    </IconButton>
                  </Box>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={1}
                    sx={{ position: 'absolute', bottom: 10, left: 10, right: 10 }}
                  >
                    <IconButton
                      onClick={sendImage}
                      sx={{
                        backgroundColor: '#25D366',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#1ebe5f',
                        },
                      }}
                    >
                      <Iconify width={24} icon="ic:baseline-send" />
                    </IconButton>
                  </Stack>
                </Box>
              )}
              {!imgUrl && (
                <>
                  <ConversationMessageList
                    messages={currentConversation?.messages || []}
                    participants={participantsInConversation}
                    loadMoreConversations={fetchMoreConversations}
                    store={selectedStore}
                    newMessage={newMessageFlag.value}
                    isLoading={loadingMessages}
                  />
 <ConversationMessageInput
                    recipients={[]}
                    onSendCompose={sendMessage}
                    onSendMessage={sendMessage}
                    currentConversationId={currentConversation?.id}
                    onShowImagePreview={onShowImagePreview}
                    onSendAudio={sendAudio}
                  />
                
                </>
              )}
           
        
          {!loadingMessages && !currentConversation?.messages && (
            <EmptyContent
              filled
              imgUrl="/assets/icons/components/ic_popover.svg"
              title={
                conversations.length > 0
                  ? 'Selecciona una conversación para visualizar los mensajes'
                  : 'Todavía no tienes conversaciones'
              }
              sx={{
                py: 10,
              }}
            />
          )}            
    </Stack>
   
    
  );

  return (
    <Container
      sx={{
        mb: { xs: 3, sm: 5 },   
        py:{xs: 0, sm: 3},
        px:{xs: 0, sm: 3}
      }}
      maxWidth={settings.themeStretch ? false : 'xl'}
    >
      <Stack       
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        {/* 
        <Card sx={{ height: {xs: '100%', sm: '90vh'}, margin: 0, paddingBottom: {xs: '25%', sm: 0}}}>
      */}
      <Card sx={{ height: {xs: '100%', sm: '90vh'}}}>
          <Stack
            sx={{
              width: 1,
              height: 1,
              overflow: 'hidden',
            }}
          >
            
            {renderHead}

            <Stack
              direction="row"
              sx={{
                width: 1,
                height: 1,
                overflow: 'hidden',
                borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
              }}
            >
              {renderMessages}

              {/* {!!currentConversation && (
                <ConversationRoom
                  orderTrigger={orderTrigger}
                  conversation={currentConversation}
                  participants={participantsInConversation}
                />
              )} */}
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </Container>
  );
}
