// @mui
import { useTheme, alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CardProps } from '@mui/material/Card';
// theme
import { bgGradient } from 'src/theme/css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Iconify from 'src/components/iconify/iconify';
import { colors } from '@mui/material';
import StoreSeachMultiple from 'src/components/store-search/store-search-multiple';


// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  description?: string;
  img?: React.ReactNode;
  action?: React.ReactNode;
  filters: any;
  onResetFilters: any;
  canReset: boolean;
  onFilters: (name: string, value: any) => void;
}

export default function MetricsFilters({ title, description, action, img, onFilters, filters, onResetFilters,canReset, ...other }: Props) {
  const theme = useTheme();
  const handleFilterStartDate = useCallback(
    (newValue: Date | null) => {
      const formattedDate = newValue ? newValue.toISOString().split('T')[0] : '';      
      onFilters('startDate', formattedDate);
    },
    [onFilters]
  );
  const [startDate] = useState<any>(new Date(filters?.startDate + 'T00:00:00'))
  const [endDate] = useState<any>(new Date(filters?.endDate + 'T23:59:59'))
  const [stores, setStores] = useState<any>(null)
  
  useEffect(() => {    
    setStores(filters?.stores);
  }, [filters?.stores])

  const handleFilterEndDate = useCallback(
    (newValue: Date | null) => {
      const formattedDate = newValue ? newValue.toISOString().split('T')[0] : '';      
      onFilters('endDate', formattedDate);
    },
    [onFilters]
  );
  const changeStoreSelection = useCallback((data: any) => {
    const STORES_ID = data.map((item: any) => item.id);
    // setCurrentStore(data[0]);
    setStores(STORES_ID);
    onFilters('stores', STORES_ID);
  }, []);

  return (
    <Stack
      flexDirection={{ xs: 'column', md: 'row' }}
      sx={{
        ...bgGradient({
          direction: '135deg',
          startColor: alpha(theme.palette.primary.light, 0.2),
          endColor: alpha(theme.palette.primary.main, 0.2),
        }),
        height: { md: 1 },
        borderRadius: 2,
        position: 'relative',
        color: 'primary.darker',
        backgroundColor: 'common.white',
      }}
      {...other}
    >
      <Stack
        flexGrow={1}
        justifyContent="center"
        alignItems={{ xs: 'center', md: 'flex-start' }}
        sx={{
          p: {
            xs: theme.spacing(5, 3, 0, 3),
            md: theme.spacing(5),
          },
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Typography paragraph variant="h4" sx={{ whiteSpace: 'pre-line' }}>
          {title}
        </Typography>
        <Typography paragraph variant="body2" sx={{ whiteSpace: 'pre-line' }} color="#637381">
          {description}
        </Typography>
        
        <Stack sx={{ minWidth: '500px' }}>
          <StoreSeachMultiple onChange={changeStoreSelection} selectedStores={stores} selectAll/>
        </Stack>
                  
        <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pl: 0,
          pr: { xs: 2.5, md: 1 },          
        }}
      >
        <DatePicker
          label="Fecha inicio"
          value={startDate}
          format='dd/MM/yyyy'
          maxDate={new Date()}
          onChange={handleFilterStartDate}        
          slotProps={{
            textField: {
              fullWidth: true              
            },
          }}
          sx={{
            maxWidth: { md: 200 },
          }}
        />
    
       

        <DatePicker
          label="Fecha fin"
          value={endDate}
          onChange={handleFilterEndDate}
          format='dd/MM/yyyy'         
          slotProps={{ textField: { fullWidth: true } }}
          sx={{
            maxWidth: { md: 200 },
          }}
        />
          
      </Stack>

        {action && action}
      </Stack>

      {img && (
        <Stack
          component="span"
          justifyContent="center"
          sx={{
            p: { xs: 5, md: 3 },
            maxWidth: 360,
            mx: 'auto',
          }}
        >
          {img}
        </Stack>
      )}
    </Stack>
  );
}
